// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '../../../../node_modules/slick-carousel/slick/slick.css';

$('#slick-blog').slick({
  slidesToShow: 4,
  slidesToScroll: 2,
  dots: true,
  fade: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,

      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,

      }
    },

  ]
});


$('#slick-logos').slick({
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
  variableWidth: false,
  arrows: false,
  autoplaySpeed: 1,
  speed: 1500,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      }
    },
  ]
});
